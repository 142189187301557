import React from 'react'

const Repeat = ({loop, setLoop, alert}) => {
    return (
<div className="repeat" style={{background: loop ? "red": "black"}} onClick={()=> {
	setLoop(!loop)
	alert(true)
}}>

<svg version="1.1" id="repeat" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 700 700" fill="white"
	 preserveAspectRatio="xMidYMin"
	 height="30px"
	 width="30px">
<title>loop</title>
<g>
	<g>
		<path d="M507.336,100.696l-96-96c-4.576-4.576-11.456-5.952-17.44-3.456c-5.984,2.496-9.888,8.288-9.888,14.752v48h-208
			c-97.216,0-176,78.784-176,176c0,8.832,7.168,16,16,16h64c8.832,0,16-7.168,16-16c0-44.192,35.808-80,80-80h208v48
			c0,6.464,3.904,12.32,9.888,14.784c5.984,2.496,12.864,1.12,17.44-3.456l96-96C513.576,117.08,513.576,106.936,507.336,100.696z"
			/>
	</g>
</g>
<g>
	<g>
		<path d="M496.008,255.992h-64c-8.832,0-16,7.168-16,16c0,44.192-35.808,80-80,80h-208v-48c0-6.464-3.904-12.32-9.888-14.784
			s-12.832-1.088-17.44,3.488l-96,96c-6.24,6.24-6.24,16.384,0,22.624l96,96c4.576,4.576,11.456,5.952,17.44,3.456
			s9.888-8.32,9.888-14.784v-48h208c97.216,0,176-78.784,176-176C512.008,263.16,504.84,255.992,496.008,255.992z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
</div>
    )
}

export default Repeat
