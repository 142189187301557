import React from "react";

const TrackSymbol = () => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512.077 512.077"
      height="40px"
      viewBox="0 0 512.077 512.077"
      width="40px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="white"
          d="m449.181 241.058c-3.444-.261 9.614-3.342-164.05 40.46-7.107 1.793-12.092 8.191-12.092 15.52 0 130.972-.004 101.061-.004 123.157-30.737-11.97-64 10.85-64 43.805 0 12.496 4.81 24.396 13.543 33.507 18.621 19.427 48.292 19.427 66.913 0 8.734-9.111 13.543-21.011 13.543-33.507v-91.01l130-33.617v48.822c-30.279-11.791-63.01 10.178-63.978 42.34-.754 25.055 18.66 46.635 43.668 48.352 29.3 2.011 50.31-18.874 50.31-52.002 0-1.218.052-171.183-.094-172.528-.767-6.944-6.368-12.72-13.759-13.299z"
        />
        <circle cx="240.034" cy="192" r="25" />
        <path
          fill="white"
          d="m243.039 382.94v-110.06c0-6.872 4.669-12.864 11.333-14.545l175.399-44.229c13.146-114.495-76.294-214.106-189.737-214.106-105.318 0-191 86.131-191 192 0 109.218 89.774 192.714 194.005 190.94zm-58.005-190.94c0-30.327 24.673-55 55-55s55 24.673 55 55-24.673 55-55 55-55-24.673-55-55z"
        />
      </g>
    </svg>
  );
};

export default TrackSymbol;
